<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>
    <div style="margin: 10px 0">
      <el-button type="primary" @click="newlyIncreased = true" class="ml-5" slot="reference">新增<i
          class="el-icon-plus"></i></el-button>
      <el-dialog title="添加分类" :visible.sync="newlyIncreased" @close="clearForm">
        <el-form :model="form">
          <el-form-item label="类名">
            <el-input v-model="form.content"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="newlyIncreased = false">取 消</el-button>
          <el-button type="primary" @click="addCategory" slot="footer">确 定</el-button>
        </div>
      </el-dialog>

      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>

    </div>
    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="categoryId" label="ID" width="80"></el-table-column>
      <el-table-column prop="content" label="类名"></el-table-column>
      <!-- <el-table-column prop="type" label="文件类型"></el-table-column>
      <el-table-column prop="size" label="文件大小(kb)"></el-table-column> -->
        <el-table-column label="子类">
          <template slot-scope="scope">
            <router-link :to="`/reclassify?id=${scope.row.categoryId}`">
              <el-button type="primary">查看</el-button>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-popconfirm
                class="ml-5"
                confirm-button-text='确定'
                cancel-button-text='我再想想'
                icon="el-icon-info"
                icon-color="red"
                title="您确定删除吗？"
                @confirm="del(scope.row.categoryId)"
            >
              <el-button type="danger" slot="reference">删除 <i class></i></el-button>
            </el-popconfirm>

            <el-button type="primary" @click="prepareUpdate(scope.row)" class="ml-5" slot="reference">修改</el-button>

            <el-dialog title="修改分类" :visible.sync="newlyIncreasedUpdate" @close="clearForm">
              <el-form :model="form" label-position="right" label-width="80px">
                <el-input v-model="form.categoryId" autocomplete="off" :style="{ visibility: 'hidden' }"></el-input>
                <el-form-item label="类名" :label-width="0">
                  <el-input v-model="form.content" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="newlyIncreasedUpdate = false">取 消</el-button>
                <el-button type="primary" @click="updateCategory" slot="footer">确 定</el-button>
              </div>
            </el-dialog>

          </template>
        </el-table-column>
    </el-table>

    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "classify",
  data() {
    return {
      newlyIncreased: false,
      newlyIncreasedUpdate: false,
      tableData: [],
      name: '',
      multipleSelection: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      form: {
        content: ''
      },
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const queryName = this.name || '';

      this.request.get("/classify/selectAll", {
        params: {
          page: this.pageNum,
          limit: this.pageSize,
          name: queryName,
        }
      }).then(res => {
        this.tableData = res.data.items
        this.total = res.data.total
      })
    },
    // changeEnable(row) {
    //   this.request.post("/classify/update/" + id, row).then(res => {
    //     if (res.code === '200') {
    //       this.$message.success("操作成功")
    //     }
    //   })
    // },
    del(id) {
      this.request.delete("/classify/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/file/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.$message.success("上传成功")
      this.load()
    },
    download(url) {
      window.open(url)
    },
    preview(categoryId) {
      this.$router.push({name: 'Reclassify', params: {id: categoryId}});
    },
    clearForm() {
      // 清空表单数据
      this.form = {
        content: '',
      };
    },

    // 添加
    addCategory() {
      this.request.post("/classify/save", {
        content: this.form.content
      }).then(res => {
        if (res.code === '200') {
          this.$message.success("添加成功");
          this.newlyIncreased = false;
          this.load();
        }
      })
    },

    // 修改按钮弹出框
    prepareUpdate(row) {
      this.form = row;
      this.newlyIncreasedUpdate = true;
      this.currentIdToUpdate = row.categoryId;
    },

    // 修改
    updateCategory() {
      this.request.post(`/classify/update/${this.currentIdToUpdate}`, {
        content: this.form.content
      }).then(res => {
        if (res.code === '200') {
          this.$message.success("操作成功")
          this.newlyIncreasedUpdate = false;
          this.load();
        }
      })
    },

  }
}
</script>

<style scoped>

</style>
